import { PARTNER_ENUM } from '@/assets/config/common/partner.config';
import { ITransferRequestModel, TransferProtocol, ITransferProtocolTypeValue, ITransferStatusResponse } from '@/models/transferFetch/tramsferModel';
import { FloatHelper } from '@/service/floatHelper';
import { numberComma } from '@/service/utils';
import { Button, ButtonGroup, Input, InputGroup, InputRightAddon, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerTableDisplayComp } from './partnerTableDisplayComp';
import { RadioCardGroup } from './RadioCard';

export type ITransferUpdateCompParams = {
    onClose?: () => void;
    onConfirm?: (request: ITransferRequestModel) => void;
    partnerId?: PARTNER_ENUM;
    agentUserId?: number;
    statusResponse?: ITransferStatusResponse;
};

type IPrototcolItem = {
    name: string;
    desc: string;
    minAmount: number;
    maxAmount: number;
    merchantAppId: number;
    paymentId: number;
};

export function TransferUpdateComp(params: ITransferUpdateCompParams) {
    const { statusResponse, partnerId, agentUserId } = params;
    const { t } = useTranslation();
    const [amountValue, setAmountValue] = useState('');

    const [requestModel, setRequestModel] = useState<ITransferRequestModel>({
        paymentId: 0,
        merchantAppId: 0,
        limitType: 1,
        appType: 2,
        protocol: TransferProtocol.TRC20,
        amount: 0,
    });

    const AMOUNT_SETTING = {
        MIN: 100,
        MAX: 300000,
    };

    const formateNumber = (num?: number) => numberComma(FloatHelper.floatFixed(num || 0, 'Floor', 0));

    const protocolList = useMemo(() => {
        if (!statusResponse) return [];
        const tempItems: IPrototcolItem[] = [];
        statusResponse.paymentInfo?.forEach((info) => {
            info.merchantList.forEach((mc) => {
                _.forEach(mc.protocolMap, (val, key) => {
                    tempItems.push({
                        name: key,
                        desc: val,
                        minAmount: mc.minAmount,
                        maxAmount: mc.maxAmount,
                        paymentId: info.paymentId,
                        merchantAppId: mc.appId,
                    });
                });
            });
        });
        const temp = tempItems.reduce(
            (mapping, cur) => {
                mapping[cur.name] = mapping[cur.name] || cur;
                mapping[cur.name].minAmount = Math.min(mapping[cur.name].minAmount, cur.minAmount);
                mapping[cur.name].maxAmount = Math.max(mapping[cur.name].maxAmount, cur.maxAmount);
                return mapping;
            },
            {} as { [key: string]: IPrototcolItem },
        );

        return Object.values(temp).map((item) => ({ text: item.name, value: item }));
    }, [statusResponse]);

    const handelNextStep = () => {
        const currentProtocol = protocolList.find((pt) => pt.value.name === requestModel.protocol);
        requestModel.merchantAppId = currentProtocol?.value.merchantAppId || 0;
        requestModel.paymentId = currentProtocol?.value.paymentId || 0;
        if (params.onConfirm) params.onConfirm(requestModel);
    };

    const isDisabled = () => {
        return requestModel.amount < AMOUNT_SETTING.MIN || requestModel.amount > AMOUNT_SETTING.MAX;
    };

    return (
        <div className="flex-col flex ">
            <div className="flex-col grow flex divide-y divide-dashed">
                <PartnerTableDisplayComp partnerId={partnerId || -1} agentUserId={agentUserId || -1} />
                {/* 账户余额 */}
                <div className="flex grow p-2">
                    <div className="basis-1/4 text-right text-gray-500">
                        <span>{t('Lbl_TransferAcountBalance')}:</span>
                    </div>
                    <div className="basis-3/4 ml-1 flex-col flex">{formateNumber(statusResponse?.walletInfo?.balance)}</div>
                </div>

                {/* 信用余额 */}
                <div className="flex grow p-2">
                    <div className="basis-1/4 text-right text-gray-500">
                        <span>{t('Lbl_TransferCreditBalance')}:</span>
                    </div>
                    <div className="basis-3/4 ml-1 flex-col flex">{formateNumber(statusResponse?.walletInfo?.validCreditAmount)}</div>
                </div>

                {/* 选择钱包协议 */}
                <div className="flex grow p-2">
                    <div className="basis-1/4 text-right  text-gray-500 flex flex-col">
                        <span>{t('Lbl_TransferChosseProtocolOptions')}:</span>
                    </div>
                    <div className="basis-3/4 ml-1 flex-col flex">
                        <div className="flex">
                            <span className="icon icon-info text-red-500 mt-1" />
                            <Text fontSize="sm" color="red.500">
                                {t('Msg_TransferProtocolWarring')}
                            </Text>
                        </div>
                        <RadioCardGroup
                            name="rcg"
                            defaultValue={protocolList[0]?.text}
                            options={protocolList.map((pt) => ({ text: pt.text, value: pt.text }))}
                            onChange={(val) => setRequestModel((pre) => ({ ...pre, protocol: val as ITransferProtocolTypeValue }))}
                        />
                        <Text fontSize="sm" color="gray.300">
                            {t(requestModel.protocol === TransferProtocol.TRC20 ? 'Msg_TransferProtocolTRC20' : 'Msg_TransferProtocolERC20')}
                        </Text>
                    </div>
                </div>

                {/* 充值金额 */}
                <div className="flex grow p-2 mb-5">
                    <div className="basis-1/4 text-right text-gray-500">
                        <span>{t('Lbl_TransferAmount')}:</span>
                    </div>
                    <div className="basis-3/4 ml-1 flex-col flex">
                        <InputGroup size="sm">
                            <Input
                                value={requestModel.amount}
                                placeholder={t('Msg_TransferAmountInfo')}
                                onChange={(e) => {
                                    if (e.target.value.includes('.')) return;
                                    const val = Number(e.target.value);
                                    if (isNaN(val)) return;
                                    setRequestModel((pre) => ({ ...pre, amount: val }));
                                    setAmountValue(val + '.');
                                }}
                            />
                            <InputRightAddon>
                                {t('Msg_TransferAmountLimit')
                                    .replace('{0}', AMOUNT_SETTING.MIN + '')
                                    .replace('{1}', AMOUNT_SETTING.MAX + '')}
                            </InputRightAddon>
                        </InputGroup>
                        <span className=" h-1">
                            <Text color="red.300" fontSize="sm">
                                {requestModel.amount === 0 && t('Msg_TransferAmountError_Min')}
                                {isDisabled() && t('Msg_TransferAmountError')}
                            </Text>
                        </span>
                    </div>
                </div>

                {/* 充值金额列表 */}
                {!!statusResponse?.amountlist && statusResponse?.amountlist.length > 0 ? (
                    <div className="flex grow p-2 justify-center">
                        <RadioCardGroup
                            name="rcg-amount"
                            defaultValue={requestModel.amount + ''}
                            options={statusResponse?.amountlist?.map((am) => ({ text: `${am}`, value: `${am}` })) || []}
                            onChange={(val) => setRequestModel((pre) => ({ ...pre, amount: Number(val) }))}
                            value={amountValue}
                        />
                    </div>
                ) : (
                    <></>
                )}

                {/* 確認 */}
                <div className="flex grow justify-center sticky bottom-0 inset-x-0 bg-white p-2">
                    <div className="ml-1 flex-col">
                        <ButtonGroup gap="2">
                            <Button colorScheme="orange" onClick={() => handelNextStep()} isDisabled={isDisabled()}>
                                {t('Btn_NextStep')}
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </div>
    );
}
