import { getEnableConfig } from '@/assets/config/common/partner.config';
import { PasswordInputComp } from '@/components/passwordInput/passwordInputComp';
import { PopupModel } from '@/components/popupModal/popupModal';
import useFetchApi from '@/hooks/useFetchApi';
import { useTranslation } from 'react-i18next';
import { ValueOf } from '@/models/page/dashboard/IDashboard';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import getAppStorageUtil, { STORAGE_KEYS } from '@/service/appStorageUtil';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    ButtonGroup,
    Divider,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    NumberInput,
    NumberInputField,
    Select,
    Text,
    Textarea,
    UseDisclosureProps,
} from '@chakra-ui/react';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '@/service/api';
import LoadingMask from '@/components/loadingMask/loadingMask';
import { DialogButtonType, DialogContentType } from '@/components/dialog/dialog';
import { showDialog } from '@/store/slices/dialog.slice';
import { useDispatch } from 'react-redux';
import { numberComma } from '@/service/utils';
import {
    CurrencyTypeEnum,
    IAgentDetailResponseModel,
    IMemberCurrencyInfo,
    IMemberInfoRequestModel,
    IMemberInfoResponsModel,
} from '@/models/page/recharge/IRechargeMonitor';
import { CurrencyMemberComp } from './currencyMemberComp';
import { FloatHelper } from '@/service/floatHelper';
import dayjs from 'dayjs';

export type IRechargePopupProps = {
    partnerId?: number;
    agentUserCode?: string;
    memberLists?: string[];
    readonly?: boolean;
} & UseDisclosureProps;

type IPartnerItem = {
    id: number;
    text: string;
};

export const RemarkTypeEnum = {
    Recharge: 2,
    Welfare: 3,
    FirstDepositBenefit: 4,
    Others: 1,
} as const;

export type IRechargeTypeValue = keyof typeof RemarkTypeEnum;

export const RechargeCategoryTypeEnum = {
    Recharge: 1,
    Rebate: 2,
    FirstDepositBonus: 3,
    Lottery: 4,
    FlowingActivities: 5,
    GuaranteedCompensation: 6,
    ContinuousCharging: 7,
    Guess: 8,
    BonusCredits: 9,
    DepositBonus: 10,
    Others: 99,
} as const;

export type IRechargeCategoryTypeValue = keyof typeof RechargeCategoryTypeEnum;

export type IRechargeTypeModel = {
    PartnerId: number;
    AgentUserId: number;
    AgentUserCode: string;
    MemberLists: string[];
    Amount: number;
    AmountStr: string;
    RechargePassword: string;
    RemarkType: ValueOf<typeof RemarkTypeEnum>;
    Multiple: number;
    CategoryId: number;
    Remark: string;
    SerialNumber: number;
};

export const RechagePupup = (params: IRechargePopupProps) => {
    const { t } = useTranslation();
    const [, getItem] = getAppStorageUtil();
    const dispatch = useDispatch();
    const UNSELECTED_NUM = -1;
    const allowMultiple: ValueOf<typeof RemarkTypeEnum>[] = [RemarkTypeEnum.Welfare, RemarkTypeEnum.Others];
    const [memberNotCheckStr, setMemberNotCheckStr] = useState<string>('');
    const [checkedMemberInfoList, setCheckedMemberInfoList] = useState<IMemberCurrencyInfo[]>([]);
    const [serialNum] = useState(+dayjs());
    const memberInfoTitleMap = {
        [CurrencyTypeEnum.RMB]: `RMB ${t('Th_ValidUser')}`,
        [CurrencyTypeEnum.USDT]: `USDT ${t('Th_ValidUser')}`,
        [CurrencyTypeEnum.UnKnown]: `${t('Th_InvalidUser')}`,
    };

    const categoryMapping: { [key in ValueOf<typeof RemarkTypeEnum>]: ValueOf<typeof RechargeCategoryTypeEnum>[] } = {
        [RemarkTypeEnum.Recharge]: [RechargeCategoryTypeEnum.Recharge],
        [RemarkTypeEnum.Welfare]: [RechargeCategoryTypeEnum.Rebate],
        [RemarkTypeEnum.FirstDepositBenefit]: [RechargeCategoryTypeEnum.FirstDepositBonus],
        [RemarkTypeEnum.Others]: [
            RechargeCategoryTypeEnum.Lottery,
            RechargeCategoryTypeEnum.FlowingActivities,
            RechargeCategoryTypeEnum.GuaranteedCompensation,
            RechargeCategoryTypeEnum.ContinuousCharging,
            RechargeCategoryTypeEnum.Guess,
            RechargeCategoryTypeEnum.BonusCredits,
            RechargeCategoryTypeEnum.DepositBonus,
            RechargeCategoryTypeEnum.Others,
        ],
    };

    const getAgentId = useCallback((agentCode: string | undefined) => {
        if (agentCode === undefined) {
            return UNSELECTED_NUM;
        }
        const items = (getItem(STORAGE_KEYS.AGENTPARTNER_KEY) || []) as IAgentPartner[];
        const _agents = items.filter((item) => {
            return item.PartnerId === params.partnerId;
        });

        const target = _agents.find((v) => v.AgentUserCode === agentCode);
        return target ? target.AgentUserId : UNSELECTED_NUM;
    }, []);

    const [rechargeModel, setRechargeModel] = useState<IRechargeTypeModel>({
        PartnerId: params.partnerId || UNSELECTED_NUM,
        AgentUserId: getAgentId(params.agentUserCode),
        AgentUserCode: params.agentUserCode || '',
        MemberLists: params.memberLists || [],
        Amount: 0,
        AmountStr: '',
        RechargePassword: '',
        RemarkType: RemarkTypeEnum.Recharge,
        Multiple: 1,
        CategoryId: categoryMapping[RemarkTypeEnum.Recharge][0],
        Remark: '',
        SerialNumber: serialNum,
    });

    const [agentDetail, setAgentDetail] = useState<IAgentDetailResponseModel>({
        USDTRate: 0,
        AgentBalance: [],
    });

    const currentAgentBalance = useMemo(() => {
        return agentDetail.AgentBalance.find((item) => item.AgentUserId === rechargeModel.AgentUserId)?.CreditBalance || 0;
    }, [rechargeModel.AgentUserCode, agentDetail.AgentBalance]);

    const popUpParams = {
        ...params,
        title: t('Lbl_RechargeMembership'),
    };

    const partners = useMemo(() => {
        return getEnableConfig().map((config) => {
            return {
                id: config.id,
                text: t(`Lbl_Partner_${config.text}`),
            } as IPartnerItem;
        });
    }, [t]);

    const partnerMapping = partners.reduce(
        (mapping, obj) => {
            mapping[obj.id] = obj;
            return mapping;
        },
        {} as { [key: number]: IPartnerItem },
    );

    const agents = useMemo(() => {
        const items = (getItem(STORAGE_KEYS.AGENTPARTNER_KEY) || []) as IAgentPartner[];

        return items.filter((item) => item.PartnerId === rechargeModel?.PartnerId);
    }, [rechargeModel.PartnerId]);

    const agentsMapping = useMemo(() => {
        const items = (getItem(STORAGE_KEYS.AGENTPARTNER_KEY) || []) as IAgentPartner[];

        return items
            .filter((item) => item.PartnerId === rechargeModel?.PartnerId)
            .reduce(
                (mapping, obj) => {
                    mapping[obj.AgentUserId] = obj;
                    return mapping;
                },
                {} as { [key: number]: IAgentPartner },
            );
    }, [rechargeModel.PartnerId]);

    const handlePartner = (item: IPartnerItem) => {
        setRechargeModel((pre) => ({ ...pre, PartnerId: item.id, AgentUserId: UNSELECTED_NUM, AgentUserCode: '', Amount: 0, AmountStr: '' }));
        setCheckedMemberInfoList([]);
    };

    const handleAgent = (item: IAgentPartner) => {
        setRechargeModel((pre) => ({ ...pre, AgentUserId: item.AgentUserId, AgentUserCode: item.AgentUserCode }));
        setCheckedMemberInfoList([]);
    };

    const handleMembers = (memberStr: string) => {
        setMemberNotCheckStr(memberStr);
    };

    const handleAddMembers = () => {
        const members = memberNotCheckStr.split(/[\s,]+/).filter((str) => str.length !== 0);

        setMemberNotCheckStr('');

        detectGetMemberBaseInfoHandler({
            PartnerId: rechargeModel.PartnerId,
            AgentUserId: rechargeModel.AgentUserId,
            MemberCodes: members,
        });
    };

    const handleRemoveMember = (memberName: string) => {
        setRechargeModel((pre) => ({ ...pre, MemberLists: pre.MemberLists.filter((m) => m !== memberName) }));
        setCheckedMemberInfoList((pre) => pre.filter((m) => m.UserName !== memberName));
    };

    const checkedMemberInfoMap = useMemo(() => {
        return checkedMemberInfoList.reduce(
            (obj, cur) => {
                obj[cur.CurrencyType] = obj[cur.CurrencyType] || [];
                obj[cur.CurrencyType] = [...obj[cur.CurrencyType], cur];
                return obj;
            },
            {} as { [key: number]: IMemberCurrencyInfo[] },
        );
    }, [checkedMemberInfoList]);

    const handleAmountFormat = useCallback(
        (value: string) => {
            value = value.length === 0 ? '0' : value;
            const reg = /^0+\d+$/;
            value = reg.test(value) ? `${Number(value)}` : value;
            switch (rechargeModel.PartnerId) {
                case 1:
                case 2: {
                    const reg = /^\d+$/;
                    const isPass = reg.test(value);
                    if (isPass) {
                        // handleAmountByPartnerId(Number(value));
                        handleAmount(value);
                        return value;
                    }
                    return rechargeModel.Amount;
                }
                case 3: {
                    const reg = /^[0-9]+\.?[0-9]*$/;
                    const isPass = reg.test(value);
                    if (isPass) {
                        handleAmount(value);
                        return value;
                    }
                    return rechargeModel.Amount;
                }
                default:
                    return value;
            }
        },
        [rechargeModel.PartnerId],
    );

    const handleAmount = (value: string) => {
        setRechargeModel((pre) => ({ ...pre, Amount: Number(value), AmountStr: value }));
    };

    const handlePassword = (password: string) => {
        setRechargeModel((pre) => ({ ...pre, RechargePassword: password }));
    };

    const handleRechargeType = (rechargeType: ValueOf<typeof RemarkTypeEnum>) => {
        const defaultCategory = categoryMapping[rechargeType][0];
        setRechargeModel((pre) => ({ ...pre, RemarkType: rechargeType, CategoryId: defaultCategory, Multiple: 1 }));
    };

    const handleMultiple = (multiple: number) => {
        setRechargeModel((pre) => ({ ...pre, Multiple: multiple }));
    };

    const handleCategory = (category: number) => {
        setRechargeModel((pre) => ({ ...pre, CategoryId: category }));
    };

    const handleRemark = (remark: string) => {
        setRechargeModel((pre) => ({ ...pre, Remark: remark }));
    };

    const rechargeTypes: ValueOf<typeof RemarkTypeEnum>[] = [
        RemarkTypeEnum.Recharge,
        RemarkTypeEnum.Welfare,
        RemarkTypeEnum.FirstDepositBenefit,
        RemarkTypeEnum.Others,
    ];

    const beforeSave = () => {
        const RMBMembers = checkedMemberInfoMap[CurrencyTypeEnum.RMB]?.length || 0;
        const USDTMembers = checkedMemberInfoMap[CurrencyTypeEnum.USDT]?.length || 0;
        const hasRMBMembers = RMBMembers > 0;
        const hasUSDTMembers = USDTMembers > 0;
        const tempFun = (amount: number, type: string, length: number) => `${numberComma(amount)} ${type} * ${length} 人 `;

        const list = [...(checkedMemberInfoMap[CurrencyTypeEnum.RMB] || []), ...(checkedMemberInfoMap[CurrencyTypeEnum.USDT] || [])];
        const rows = 2;
        const cols = Math.ceil(list.length / rows);
        const currencyStyle = (type: number | undefined) => {
            if (type === CurrencyTypeEnum.RMB) {
                return 'text-yellow-600';
            } else if (type === CurrencyTypeEnum.USDT) {
                return 'text-green-600';
            }
            return '';
        };

        const tableRows = Array(cols)
            .fill(null)
            .map((_, index) => {
                return (
                    <tr key={`rmb-usdt-m-${index}`} className="even:bg-stone-250">
                        {Array(rows)
                            .fill(null)
                            .map((_, i) => {
                                const currencyType = list[i + index * rows]?.CurrencyType;
                                return (
                                    <td key={`rmb-usdt-tr-${i + index * rows}`} className={`p-2 w-1/2 border border-slate-300 ${currencyStyle(currencyType)}`}>
                                        <span>{list[i + index * rows]?.UserName || ''}</span>
                                        {currencyType === CurrencyTypeEnum.RMB ? (
                                            <span>(RMB)</span>
                                        ) : currencyType === CurrencyTypeEnum.USDT ? (
                                            <span>(USDT)</span>
                                        ) : null}
                                    </td>
                                );
                            })}
                    </tr>
                );
            });

        const RMBAmount = rechargeModel.Amount * RMBMembers;
        const USDTAmount = rechargeModel.Amount * USDTMembers;
        const totalAmount = FloatHelper.plus(RMBAmount, FloatHelper.times(USDTAmount, agentDetail.USDTRate));
        const temp = (
            <div className="flex flex-col">
                <table className="border-collapse border border-slate-400">
                    <thead className="bg-stone-300">
                        <tr>
                            <th className="border border-slate-300 text-center" colSpan={2}>
                                {t('Th_userName')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
                <div className="grid grid-cols-2 bg-stone-300">
                    <div className="self-center text-center">{t('Lbl_MemberRechargeTotal')}</div>
                    <div className="p-1">
                        <ul className="flex flex-col ml-1 font-bold">
                            {hasRMBMembers && <li>{tempFun(rechargeModel.Amount, '(RMB)', RMBMembers)}</li>}
                            {hasUSDTMembers && <li>{tempFun(rechargeModel.Amount, '(USDT)', USDTMembers)}</li>}
                        </ul>
                        <div className="flex self-center font-bold">{`${t('Lbl_TotalUpAmount')}:  ${numberComma(totalAmount)} (RMB)`}</div>
                    </div>

                    <div className="self-center text-center">
                        <span>{t('Th_agentNickName')}</span>
                    </div>
                    <div className=" ml-1 font-bold">
                        {!!agentsMapping[rechargeModel.AgentUserId] ? agentsMapping[rechargeModel.AgentUserId].NickName : rechargeModel.AgentUserCode}(
                        {partnerMapping[rechargeModel.PartnerId]?.text})
                    </div>

                    <div className="self-center text-center">
                        <span>{t('Lbl_ParentCategory')}</span>
                    </div>
                    <div className=" ml-1 font-bold">{t(`Lbl_RechargeType_${rechargeModel.RemarkType}`)}</div>

                    <div className="self-center text-center">
                        <span>{t('Txt_ChildCategory')}</span>
                    </div>
                    <div className=" ml-1 font-bold">{t(`Lbl_RechargeCategoryType_${rechargeModel.CategoryId}`)}</div>

                    {allowMultiple.includes(rechargeModel.RemarkType) ? (
                        <>
                            <div className="self-center text-center">
                                <span>{t('Lbl_Multiple')}</span>
                            </div>
                            <div className=" ml-1 font-bold">{rechargeModel.Multiple}</div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
        const Result = () => {
            return (
                <>
                    <div className="flex flex-col w-full">
                        <div className="flex self-center mb-2">{temp}</div>
                    </div>
                </>
            );
        };

        dispatch(
            showDialog({
                title: t('Lbl_ConfirmBelowInfo'),
                content: <Result />,
                contentType: DialogContentType.Element,
                onClose: () => {},
                onConfirm: () => {
                    onSave();
                },
                type: DialogButtonType.Info,
            }),
        );
    };

    const onSave = () => {
        detectChangeAndFetchHandler({
            ...rechargeModel,
            MemberLists: params.readonly
                ? rechargeModel.MemberLists
                : checkedMemberInfoList.filter((item) => item.CurrencyType !== CurrencyTypeEnum.UnKnown).map((item) => item.UserName),
        });
    };

    const dispatchDialog = useCallback(() => {
        const temp = [CurrencyTypeEnum.RMB, CurrencyTypeEnum.USDT, CurrencyTypeEnum.UnKnown]
            .filter((key) => checkedMemberInfoMap[key] && checkedMemberInfoMap[key].length > 0)
            .map((key) => `${memberInfoTitleMap[key]}: ${checkedMemberInfoMap[key].map((it) => it.UserName).join(', ')}`)
            .join('\n');

        dispatch(
            showDialog({
                title: t('Lbl_Button_Info'),
                content: `${t('Msg_AddRechargeSuccessMessage')}\n ${temp}`,
                onClose: params.onClose,
                type: DialogButtonType.Info,
            }),
        );
    }, [t, params.onClose, checkedMemberInfoList]);

    const isEnableSave = useMemo<boolean>(() => {
        if (rechargeModel.PartnerId === UNSELECTED_NUM) {
            return false;
        }

        if (rechargeModel.AgentUserId === UNSELECTED_NUM) {
            return false;
        }

        // if (rechargeModel.MemberLists.length === 0) {
        //     return false;
        // }

        if (checkedMemberInfoList.length === 0) {
            return false;
        }

        if (rechargeModel.Amount <= 0) {
            return false;
        }

        if (rechargeModel.RechargePassword.length === 0) {
            return false;
        }

        return true;
    }, [
        rechargeModel.PartnerId,
        rechargeModel.AgentUserId,
        rechargeModel.MemberLists.length,
        rechargeModel.Amount,
        rechargeModel.RechargePassword,
        checkedMemberInfoList,
    ]);

    const dispatchErrorDialog = useCallback(
        (message: string, data: any) => {
            let errorMessage;
            switch (message.toLowerCase().replace(' ', '')) {
                case 'createfailed':
                    {
                        const invalidMembers = (data as string[]).join(', ');
                        errorMessage = `${t('Txt_Recharge_Create_Error_CreateFailed')}: ${invalidMembers}`;
                    }
                    break;
                case 'duplicaterequest':
                    {
                        errorMessage = `${t('Txt_Recharge_Create_Error_DuplicateRequest')}`;
                    }
                    break;
                case 'error':
                    {
                        errorMessage = t('Txt_Error_Network_Issue');
                    }
                    break;
                default:
                    errorMessage = t('Txt_fail');
            }

            dispatch(
                showDialog({
                    title: t('Lbl_Button_Info'),
                    content: errorMessage,
                    type: DialogButtonType.Info,
                }),
            );
        },
        [t],
    );

    const [isLoading, setIsLoading] = useState(false);
    const detectChangeAndFetchHandler = useFetchApi<IRechargeTypeModel, any, IRechargeTypeModel>(
        api.createRechargeRecord,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [_payload] }) => {
            if (value.data.IsSuccess) {
                dispatchDialog();
            } else {
                dispatchErrorDialog(value.data.Message, value.data.Data);
            }
            setIsLoading(false);
            return value.data.Data;
        },
        [rechargeModel],
        (error) => {
            setIsLoading(false);
            dispatchErrorDialog('error', '');
        },
    );

    const detectGetAgentDetailHandler = useFetchApi<object, IAgentDetailResponseModel, object>(
        api.agentDetail,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [_payload] }) => {
            setAgentDetail(value.data.Data);
            setIsLoading(false);
            return value.data.Data;
        },
        [rechargeModel],
        (error) => {
            setIsLoading(false);
        },
    );

    const detectGetMemberBaseInfoHandler = useFetchApi<IMemberInfoRequestModel, IMemberInfoResponsModel, IMemberInfoRequestModel>(
        api.memberBaseInfo,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [_payload] }) => {
            if (value.data.IsSuccess) {
                const data = value.data.Data;
                const newList = data.ValidMembers.map((item) => ({ UserName: item.UserName, CurrencyType: item.CurrencyId }) as IMemberCurrencyInfo);
                const invalidList = data.InvalidMembers.map((item) => ({ UserName: item, CurrencyType: CurrencyTypeEnum.UnKnown }) as IMemberCurrencyInfo);
                setCheckedMemberInfoList((pre) => [...pre, ...newList, ...invalidList]);
            }
            setIsLoading(false);
            return value.data.Data;
        },
        [],
        (error) => {
            setIsLoading(false);
        },
    );

    useEffect(() => {
        if (!params.isOpen) {
            return;
        }

        detectGetAgentDetailHandler({});

        if (params.readonly) {
            detectGetMemberBaseInfoHandler({
                PartnerId: rechargeModel.PartnerId,
                AgentUserId: rechargeModel.AgentUserId,
                MemberCodes: params.memberLists || [],
            });
        }
    }, [params.isOpen]);

    return (
        <>
            <LoadingMask visible={isLoading} />
            <PopupModel {...popUpParams}>
                <div className="flex-col flex ">
                    <div className="flex-col mt-2 grow flex">
                        {/* 品牌 */}
                        <div className="flex grow m-2">
                            <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                                <span>{t('Th_partnerId')}:</span>
                            </div>
                            <div className="basis-3/4 ml-1 flex-col flex">
                                {params.readonly ? (
                                    <Text align="center">{partnerMapping[rechargeModel.PartnerId]?.text}</Text>
                                ) : (
                                    <Menu matchWidth={true}>
                                        <MenuButton as={Button}>
                                            {rechargeModel.PartnerId !== UNSELECTED_NUM
                                                ? partnerMapping[rechargeModel.PartnerId].text
                                                : t('Msg_PleaseSelectPartner')}
                                            <ChevronDownIcon />
                                        </MenuButton>
                                        <MenuList>
                                            {partners.map((item) => (
                                                <MenuItem
                                                    color={rechargeModel.PartnerId === item.id ? 'gray.100' : ''}
                                                    bg={rechargeModel?.PartnerId === item.id ? 'orange' : ''}
                                                    _hover={{ bg: 'orange', color: 'gray.100' }}
                                                    key={`MI_${item.id}`}
                                                    value={item.id}
                                                    onClick={() => handlePartner(item)}>
                                                    {item.text}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                )}
                            </div>
                        </div>

                        {/* 代理 */}
                        <div className="flex grow m-2">
                            <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                                <span>{t('Th_agentNickName')}:</span>
                            </div>
                            <div className="basis-3/4 ml-1 flex-col flex">
                                {params.readonly ? (
                                    <Text align="center">{rechargeModel.AgentUserCode}</Text>
                                ) : (
                                    <Menu matchWidth={true}>
                                        <MenuButton as={Button}>
                                            {rechargeModel.AgentUserId !== UNSELECTED_NUM
                                                ? agentsMapping[rechargeModel.AgentUserId].NickName
                                                : t('Msg_PleaseSelectAgent')}
                                            <ChevronDownIcon />
                                        </MenuButton>
                                        <MenuList>
                                            {agents.map((item) => (
                                                <MenuItem
                                                    color={rechargeModel?.AgentUserId === item.AgentUserId ? 'gray.100' : ''}
                                                    bg={rechargeModel?.AgentUserId === item.AgentUserId ? 'orange' : ''}
                                                    _hover={{ bg: 'orange', color: 'gray.100' }}
                                                    key={`AG_${item.AgentUserId}`}
                                                    value={item.AgentUserId}
                                                    onClick={() => handleAgent(item)}>
                                                    {item.NickName}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                )}
                            </div>
                        </div>

                        {/* 会员账号 */}
                        <div className="flex grow m-2">
                            <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                                <span>{t('Lbl_MemberAccount')}:</span>
                            </div>
                            <div className="basis-3/4 ml-1 flex-col flex">
                                {params.readonly ? (
                                    <div className=" flex justify-center">
                                        <Text align="center">{rechargeModel.MemberLists[0]}</Text>
                                        <span className="ml-1 font-bold text-sm">
                                            {checkedMemberInfoList[0]?.CurrencyType === CurrencyTypeEnum.USDT ? <>(USDT)</> : <></>}
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                        <Textarea placeholder="" value={memberNotCheckStr} onChange={(e) => handleMembers(e.target.value)} />
                                        <div className=" mt-1 flex justify-end">
                                            <button
                                                disabled={rechargeModel.AgentUserCode === '' || memberNotCheckStr.replaceAll(' ', '').length === 0}
                                                className="mx-1 rounded p-1 text-sm text-gray-100 bg-red-500 data-active:bg-gray-300 disabled:bg-red-100"
                                                onClick={() => handleAddMembers()}>
                                                {t('Lbl_Add')}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        {/* 会员已加入區 */}
                        <div className="flex grow m-2">
                            <div className="basis-1/4 text-right text-sm mt-2 text-gray-500" />
                            <div className="basis-3/4 ml-1 flex flex-wrap">
                                {params.readonly ? (
                                    <></>
                                ) : (
                                    [CurrencyTypeEnum.RMB, CurrencyTypeEnum.USDT, CurrencyTypeEnum.UnKnown]
                                        .filter((key) => !!checkedMemberInfoMap[key] && checkedMemberInfoMap[key].length > 0)
                                        .map((key) => (
                                            <Fragment key={`rp-ch-mi-${key}`}>
                                                <Divider />
                                                <div className="flex flex-col">
                                                    <div>
                                                        <span className=" text-sm">
                                                            {memberInfoTitleMap[key]} ({checkedMemberInfoMap[key].length}位){' '}
                                                            {key === CurrencyTypeEnum.USDT ? <span className=" font-bold" /> : <></>}：
                                                        </span>
                                                    </div>
                                                    <div>
                                                        {checkedMemberInfoMap[key].map((item) => (
                                                            <CurrencyMemberComp
                                                                key={`cmc-key-${item.UserName}`}
                                                                userName={item.UserName}
                                                                onClick={() => handleRemoveMember(item.UserName)}
                                                                currencyType={item.CurrencyType}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))
                                )}
                            </div>
                        </div>

                        {/* 代充金額 */}
                        <div className="flex grow m-2">
                            <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                                <span>{t('Lbl_UpAmount')}:</span>
                            </div>
                            <div className="basis-3/4 ml-1 flex-col flex">
                                <Input
                                    disabled={rechargeModel.PartnerId === UNSELECTED_NUM}
                                    placeholder={t('Msg_PleaseInputRechargeAmount')}
                                    autoComplete="none"
                                    maxLength={9}
                                    value={rechargeModel.AmountStr}
                                    isInvalid={rechargeModel.Amount > currentAgentBalance}
                                    onChange={(e) => {
                                        handleAmountFormat(e.target.value);
                                    }}
                                />
                                <div className=" text-sm mt-1">
                                    <span className="mr-1">{`${t('Lbl_RemainingBalance')}: `}</span>{' '}
                                    <span className="mr-1 font-bold">{`${numberComma(currentAgentBalance)}`}</span>
                                    {rechargeModel.PartnerId === 3 ? (
                                        <>
                                            (<span className="mr-1">{`USDT ${t('Lbl_ExchangeRate')}: `}</span>
                                            <span className="mr-1 font-bold">{`${agentDetail.USDTRate}`}</span>)
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* 資金密碼 */}
                        <div className="flex grow m-2">
                            <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                                <span>{t('Lbl_FundPassword')}:</span>
                            </div>
                            <div className="basis-3/4 ml-1 flex-col flex">
                                <PasswordInputComp placeHolder={t('Msg_PleaseInputFundPassword')} onChange={handlePassword} />
                            </div>
                        </div>

                        {/* 父类别 - 代充選項 */}
                        <div className="flex grow m-2">
                            <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                                <span>{t('Lbl_ParentCategory')}:</span>
                            </div>
                            <div className="basis-3/4 ml-1 flex-col flex">
                                <Select
                                    onChange={(e) => {
                                        handleRechargeType(Number(e.target.value) as ValueOf<typeof RemarkTypeEnum>);
                                    }}>
                                    {rechargeTypes.map((rt) => (
                                        <option key={`cat_opt_${rt}`} value={rt}>
                                            {t(`Lbl_RechargeType_${rt}`)}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        {/* 子类别 */}
                        <div className="flex grow m-2">
                            <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                                <span>{t('Lbl_ChildCategory')}:</span>
                            </div>
                            <div className="basis-3/4 ml-1 flex-col flex">
                                <Select
                                    onChange={(e) => {
                                        handleCategory(Number(e.target.value));
                                    }}>
                                    {categoryMapping[rechargeModel.RemarkType].map((item) => (
                                        <option key={`cat_opt_${item}`} value={item}>
                                            {t(`Lbl_RechargeCategoryType_${item}`)}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        {/* 流水倍数 */}
                        {allowMultiple.includes(rechargeModel.RemarkType) ? (
                            <div className="flex grow m-2">
                                <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                                    <span>{t('Lbl_Multiple')}:</span>
                                </div>
                                <div className="basis-3/4 ml-1 flex-col flex">
                                    <ButtonGroup gap="2">
                                        {[1, 3, 5].map((mul) => (
                                            <Button
                                                key={`mul_${mul}`}
                                                colorScheme={mul === rechargeModel.Multiple ? 'orange' : 'gray'}
                                                onClick={() => handleMultiple(mul)}>
                                                {mul}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {/* 备注信息 */}
                        <div className="flex grow m-2">
                            <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                                <span>{t('Lbl_Memo')}:</span>
                            </div>
                            <div className="basis-3/4 ml-1 flex-col flex">
                                <Textarea
                                    placeholder={t(`Lbl_RechargeType_${rechargeModel.RemarkType}`)}
                                    maxLength={50}
                                    onChange={(e) => {
                                        handleRemark(e.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        {/* 確認 */}
                        <div className="flex grow justify-center sticky bottom-0 inset-x-0 bg-white">
                            <div className="ml-1 flex-col">
                                <ButtonGroup gap="2">
                                    <Button colorScheme="gray" onClick={() => params.onClose && params.onClose()}>
                                        {t('Lbl_Button_Cancle')}
                                    </Button>
                                    <Button isDisabled={!isEnableSave} colorScheme="orange" data-sn={serialNum} onClick={() => beforeSave()}>
                                        {t('Lbl_Button_Confirm')}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </PopupModel>
        </>
    );
};
