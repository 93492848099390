import BettingRateNote from '@/components/bettingRateNote/bettingRateNote';
import { baseRequest } from '@/models/apiRequest/baseRequest';
import { TableColumnStyle, TableHeaderType, TableLayoutConfig, TableSearchEnum } from '@/models/layout/tableLayout';
import { DepositTradeType, SearchDepositTradeStatus } from '@/models/page/report/IChargeAndWithdrawal';
import { IReportCommission, IReportCommissionData, MemberCommission } from '@/models/page/report/IReportCommission';
import { ReportPageEnum } from '@/models/transfer/reportTransfer';
import { api } from '@/service/api';
import { numberListMapping, toFirstUpperCase } from '@/service/utils';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from '@/constants/datetime';
import { PermissionsId } from '@/models/permissions';

const todayRequest = {
    ...baseRequest,
    DateFrom: dayjs().startOf('day').format(DATETIME_FORMAT.YMDHMSS),
    DateTo: dayjs().format(DATETIME_FORMAT.YMDHMSS),
};

const thisMonthRequest = {
    ...baseRequest,
    DateFrom: dayjs().startOf('month').format(DATETIME_FORMAT.YMDHMSS),
    DateTo: dayjs().format(DATETIME_FORMAT.YMDHMSS),
};

export const tableLayoutConfig: TableLayoutConfig[] = [
    {
        pageName: 'fund',
        pageId: ReportPageEnum.FUND,
        permissionId: PermissionsId.FundReport,
        api: { ...api.fundReport, initParams: { ...todayRequest, ...{ UserIdList: undefined } } },
        direactableKeyList: [
            { key: 'newUser', directLayout: ReportPageEnum.FUND_NEW_ACCOUNT, payload: { forBeforeFetch: ['date', 'partnerId', 'userIdList'] } },
        ],
        menuVisible: true,
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.DATE_RANGE,
                dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo' },
                label: '数据时间段',
            },
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'partnerId',
                label: '品牌',
                placeholder: '全部',
                selectOptions: [],
            },
            {
                type: TableSearchEnum.MULTI_SELECT,
                matchKey: 'userIdList',
                label: '所属代理',
                selectOptions: [],
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'fund-new-account',
        pageId: ReportPageEnum.FUND_NEW_ACCOUNT,
        permissionId: PermissionsId.FundReport,
        api: {
            ...api.registerMembers,
            initParams: { ...baseRequest },
            insertParamsBeforeFetch: (request, refList) => {
                const getDate = refList?.find((v) => v.key === 'date')?.value;
                const dateObj = getDate ? dayjs(getDate) : dayjs();
                request.DateFrom = dateObj.startOf('day').format(DATETIME_FORMAT.YMDHMSS);
                request.DateTo = dateObj.endOf('day').format(DATETIME_FORMAT.YMDHMSS);
                const partnerId = refList?.find((v) => v.key === 'partnerId')?.value;
                request.PartnerId = partnerId === undefined ? 0 : ~~parseInt(partnerId);
                request.UserIdList = refList?.find((v) => v.key === 'userIdList')?.value || [];
                return request;
            },
        },
        direactableKeyList: [{ key: 'userName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        headerType: TableHeaderType.NONE,
        search: [
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'partnerId',
                label: '品牌',
                placeholder: '全部',
                selectOptions: [],
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'commission',
        pageId: ReportPageEnum.COMMISSION,
        permissionId: PermissionsId.CommissionReport,
        api: {
            ...api.commissionReport,
            initParams: { ...thisMonthRequest, ...{ UserIdList: undefined } },
            insertParamsBeforeFetch: (request) => {
                return {
                    ...request,
                    DateFrom: dayjs(request.DateFrom).startOf('month').format(DATETIME_FORMAT.YMD),
                    DateTo: dayjs(request.DateTo).endOf('month').format(DATETIME_FORMAT.YMD),
                };
            },
        },
        direactableKeyList: [
            {
                key: 'commAmount',
                directLayout: ReportPageEnum.COMMISSION_MEMBER,
                payload: { forBeforeTrensfer: ['date', 'agentUserId'], forBeforeFetch: ['partnerId', 'userIdList', 'dateFrom', 'dateTo'] },
            },
            {
                key: 'proxyComm',
                directLayout: ReportPageEnum.COMMISSION_AGENT_LEVEL,
                payload: { forBeforeTrensfer: ['date', 'agentUserId'], forBeforeFetch: ['date', 'partnerId', 'agentUserId', 'userIdList'] },
            },
        ],
        menuVisible: true,
        headerType: TableHeaderType.SEARCH_WITH_TABLE,
        search: [
            {
                type: TableSearchEnum.DATE_RANGE_MONTH,
                dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo' },
                // minDate: dayjs().subtract(3, 'month').toDate().toDateString(),
                label: '数据时间段',
            },
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'partnerId',
                label: '品牌',
                placeholder: '全部',
                selectOptions: [],
            },
            {
                type: TableSearchEnum.MULTI_SELECT,
                matchKey: 'userIdList',
                label: '所属代理',
                selectOptions: [],
            },
        ],
        isShowPagging: false,
        needFold: true,
    },
    {
        pageName: 'commission-member',
        pageId: ReportPageEnum.COMMISSION_MEMBER,
        permissionId: PermissionsId.CommissionReport,
        api: {
            ...api.commissionReport,
            initParams: { ...baseRequest },
            insertParamsBeforeFetch: (request, refList) => {
                const partnerId = refList?.find((v) => v.key === 'partnerId')?.value || '0';
                request.PartnerId = ~~partnerId;
                request.UserIdList = refList?.find((v) => v.key === 'userIdList')?.value || [];
                request.DateFrom = refList?.find((v) => v.key === 'dateFrom')?.value || dayjs().format(DATETIME_FORMAT.YMDHMSS);
                request.DateTo = refList?.find((v) => v.key === 'dateTo')?.value || dayjs().format(DATETIME_FORMAT.YMDHMSS);
                return request;
            },
            beforeTransfer: (resp: IReportCommission, refList = []): MemberCommission[] => {
                const target = resp.Data.find((item) => {
                    return refList.every((v) => {
                        const key = toFirstUpperCase(v.key) as keyof IReportCommissionData;
                        return item[key].toString() === v.value;
                    });
                });

                return target && target.MemberCommission ? [target.MemberCommission] : [];
            },
        },
        direactableKeyList: [
            { key: 'userCount', directLayout: ReportPageEnum.COMMISSSION_MEMBER_DETAIL, payload: { forBeforeFetch: ['date', 'partnerId', 'agentUserId'] } },
            {
                key: 'rechargAndWithdrawfee',
                directLayout: ReportPageEnum.COMMISSION_RECHARGE_WITHDRAW_FEE,
                payload: { forBeforeFetch: ['date', 'partnerId', 'agentUserId'] },
            },
        ],
        headerType: TableHeaderType.TEXT,
        headerText: [
            { title: 'Th_commAmount', key: 'amount', keyRef: 'tableBody', type: TableColumnStyle.CommaNumeric },
            { title: 'Th_date', key: 'date', keyRef: 'tableBody', type: TableColumnStyle.DateMonth },
        ],
        isShowPagging: true,
        needFold: true,
    },
    {
        pageName: 'commission-agent-level',
        pageId: ReportPageEnum.COMMISSION_AGENT_LEVEL,
        permissionId: PermissionsId.CommissionReport,
        api: {
            ...api.proxyCommission,
            initParams: { ...baseRequest },
            insertParamsBeforeFetch: (request, refList) => {
                // request必須要加一個Date欄位, 該Date必須是該月的第一天
                const getDate = dayjs(refList?.find((v) => v.key === 'date')?.value);

                return {
                    ...request,
                    AgentUserId: ~~(refList?.find((v) => v.key === 'agentUserId')?.value || 0),
                    PartnerId: ~~(refList?.find((v) => v.key === 'partnerId')?.value || 0),
                    UserIdList: refList?.find((v) => v.key === 'userIdList')?.value || [],
                    Date: getDate.startOf('month').format(DATETIME_FORMAT.YMD),
                    DateFrom: getDate.startOf('month').format(DATETIME_FORMAT.YMD),
                    DateTo: getDate.endOf('month').format(DATETIME_FORMAT.YMD),
                };
            },
        },
        headerType: TableHeaderType.SWITCHER,
        isShowPagging: true,
    },
    {
        pageName: 'commission-member-detail',
        pageId: ReportPageEnum.COMMISSSION_MEMBER_DETAIL,
        permissionId: PermissionsId.CommissionReport,
        api: {
            ...api.commissionMember,
            initParams: { ...thisMonthRequest },
            insertParamsBeforeFetch: (request, refList) => {
                // request必須要加一個Date欄位, 該Date必須是該月的第一天
                const getDate = dayjs(refList?.find((v) => v.key === 'date')?.value);

                return {
                    ...request,
                    AgentUserId: ~~refList?.find((v) => v.key === 'agentUserId')?.value || 0,
                    PartnerId: ~~refList?.find((v) => v.key === 'partnerId')?.value || 0,
                    Date: getDate.startOf('month').format(DATETIME_FORMAT.YMD),
                    DateFrom: getDate.startOf('month').format(DATETIME_FORMAT.YMD),
                    DateTo: getDate.endOf('month').format(DATETIME_FORMAT.YMD),
                };
            },
        },
        direactableKeyList: [{ key: 'userName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.INPUT,
                matchKey: 'userName',
                label: '会员帐号',
                placeholder: '请输入会员帐号',
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'commission-recharge-withdraw-fee',
        pageId: ReportPageEnum.COMMISSION_RECHARGE_WITHDRAW_FEE,
        permissionId: PermissionsId.CommissionReport,
        api: {
            ...api.depositDetails,
            initParams: { ...baseRequest, SearchTradeType: DepositTradeType.ALL, SearchStatus: SearchDepositTradeStatus.SUCCESS },
            insertParamsBeforeFetch: (request, refList) => {
                const getDate = refList?.find((v) => v.key === 'date')?.value;
                const dateObj = getDate ? dayjs(getDate) : dayjs();
                request.DateFrom = dateObj.startOf('day').format(DATETIME_FORMAT.YMDHMSS);
                request.DateTo = dateObj.endOf('month').format(DATETIME_FORMAT.YMDHMSS);
                const partnerId = refList?.find((v) => v.key === 'partnerId')?.value || '0';
                request.PartnerId = ~~partnerId;
                const agentUserId = refList?.find((v) => v.key === 'agentUserId')?.value || '0';
                request.AgentUserId = ~~agentUserId;
                return request;
            },
        },
        direactableKeyList: [{ key: 'userName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        headerType: TableHeaderType.SEARCH_WITH_TEXT,
        headerText: [
            { title: 'Th_fee', key: 'commFee', keyRef: 'tableFooter', type: TableColumnStyle.CommaNumeric },
            { title: 'Th_date', key: 'createTime', keyRef: 'tableBody', type: TableColumnStyle.DateMonth },
        ],
        search: [
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'searchTradeType',
                label: '交易类型',
                // placeholder: '全部',
                selectOptions: [
                    { id: 0, text: 'Lbl_Partner_All' },
                    { id: 1, text: 'Lbl_TradeType_1' },
                    { id: 2, text: 'Lbl_TradeType_2' },
                ],
            },
            {
                type: TableSearchEnum.INPUT,
                matchKey: 'userName',
                label: '会员帐号',
                placeholder: '请输入会员帐号',
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'team',
        pageId: ReportPageEnum.TEAM,
        permissionId: PermissionsId.TeamDataReport,
        api: { ...api.teamReport, initParams: { ...thisMonthRequest, ...{ UserIdList: undefined } } },
        // direactableKeyList: [{ key: 'userName', isPopupModel: true }],
        menuVisible: true,
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.DATE_RANGE,
                dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo' },
                label: '数据时间段',
            },
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'partnerId',
                label: '品牌',
                placeholder: '全部',
                selectOptions: [],
            },
            {
                type: TableSearchEnum.MULTI_SELECT,
                matchKey: 'userIdList',
                label: '所属代理',
                selectOptions: [],
            },
            // {
            //     type: TableSearchEnum.INPUT,
            //     matchKey: 'userName',
            //     label: '代理帐号',
            //     placeholder: '请输入代理帐号',
            // },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'member',
        pageId: ReportPageEnum.MEMBER,
        permissionId: PermissionsId.MemberDataReport,
        api: {
            ...api.memberReport,
            initParams: {
                ...thisMonthRequest,
                ...{
                    DateFrom: dayjs().date(1).format(DATETIME_FORMAT.YMDHMSS),
                    DateTo: dayjs().format(DATETIME_FORMAT.YMDHMSS),
                    RegisterDate: {
                        start: dayjs('2018-01-01').startOf('day').format(DATETIME_FORMAT.YMDHMSS),
                        end: dayjs().format(DATETIME_FORMAT.YMDHMSS),
                    },
                    UserIdList: undefined,
                    Enable: 1,
                    VipLevel: -1,
                },
            },
        },
        direactableKeyList: [{ key: 'userName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        menuVisible: true,
        headerType: TableHeaderType.SEARCH_COLUMN,
        search: [
            [
                {
                    type: TableSearchEnum.DATE_RANGE,
                    dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo', shortcuts: false },
                    label: '数据时间段',
                },
                {
                    type: TableSearchEnum.SELECT,
                    matchKey: 'partnerId',
                    label: '品牌',
                    placeholder: '全部',
                    selectOptions: [],
                },
                {
                    type: TableSearchEnum.MULTI_SELECT,
                    matchKey: 'userIdList',
                    label: '所属代理',
                    selectOptions: [],
                },
                {
                    type: TableSearchEnum.SELECT,
                    matchKey: 'unBetDays',
                    label: '未下注天数',
                    selectOptions: [
                        { id: 0, text: 'Lbl_Partner_All' },
                        { id: 1, text: 'Txt_7-15' },
                        { id: 2, text: 'Txt_15-30' },
                        { id: 3, text: 'Txt_60' },
                    ],
                },
                {
                    type: TableSearchEnum.INPUT,
                    matchKey: 'userName',
                    label: '会员帐号',
                    placeholder: '请输入会员帐号',
                    hasFuzzy: true,
                },
            ],
            [
                {
                    type: TableSearchEnum.DATE_RANGE,
                    matchKey: 'registerDate',
                    dateMatchKey: { startDate: 'start', endDate: 'end', shortcuts: false },
                    label: '注册时间',
                },
                {
                    type: TableSearchEnum.RANGE_SELECT,
                    label: '充值金额',
                    placeholder: '不限',
                    matchKey: 'rechargeAmount',
                    rangeSelectOptions: {
                        start: numberListMapping.n0to70000.map((text, id) => ({ id, text: text.toString() })),
                        end: numberListMapping.n500toi.map((text, id) => ({ id, text: text.toString() })),
                    },
                },
                {
                    type: TableSearchEnum.RANGE_SELECT,
                    label: '代充金额',
                    placeholder: '不限',
                    matchKey: 'upAmount',
                    rangeSelectOptions: {
                        start: numberListMapping.n0to70000.map((text, id) => ({ id, text: text.toString() })),
                        end: numberListMapping.n500toi.map((text, id) => ({ id, text: text.toString() })),
                    },
                },
                {
                    type: TableSearchEnum.RANGE_SELECT,
                    label: '输赢',
                    placeholder: '不限',
                    matchKey: 'winLose',
                    rangeSelectOptions: {
                        start: numberListMapping.nnito5000.map((text, id) => ({ id, text: text.toString() })),
                        end: numberListMapping.nn5000toi.map((text, id) => ({ id, text: text.toString() })),
                    },
                },
                {
                    type: TableSearchEnum.RANGE_SELECT,
                    label: '投注金额',
                    placeholder: '不限',
                    matchKey: 'betAmount',
                    rangeSelectOptions: {
                        start: numberListMapping.n0to15000.map((text, id) => ({ id, text: text.toString() })),
                        end: numberListMapping.n500toi.map((text, id) => ({ id, text: text.toString() })),
                    },
                },
                {
                    type: TableSearchEnum.SELECT,
                    label: '当月有效会员',
                    matchKey: 'enable',
                    selectOptions: [
                        { id: 0, text: 'Txt_NoLimit' },
                        { id: 1, text: 'Txt_ValidMember' },
                        { id: 2, text: 'Txt_InvalidMember' },
                    ],
                },
            ],
            [
                {
                    type: TableSearchEnum.SELECT,
                    label: 'VIP等級',
                    matchKey: 'VipLevel',
                    selectOptions: [
                        { id: -1, text: 'Lbl_selectAll' },
                        { id: 0, text: '0', withoutI18n: true },
                        { id: 1, text: '1', withoutI18n: true },
                        { id: 2, text: '2', withoutI18n: true },
                        { id: 3, text: '3', withoutI18n: true },
                        { id: 4, text: '4', withoutI18n: true },
                        { id: 5, text: '5', withoutI18n: true },
                        { id: 6, text: '6', withoutI18n: true },
                        { id: 7, text: '7', withoutI18n: true },
                        { id: 8, text: '8', withoutI18n: true },
                        { id: 9, text: '9', withoutI18n: true },
                    ],
                },
            ],
        ],
        isShowPagging: true,
    },
    {
        pageName: 'game',
        pageId: ReportPageEnum.GAME,
        permissionId: PermissionsId.GameWinLossReport,
        api: { ...api.gameReport, initParams: { ...todayRequest, ...{ UserIdList: undefined } } },
        direactableKeyList: [
            {
                key: 'users',
                directLayout: ReportPageEnum.GAME_BRAND,
                payload: { forTitleLabelKey: 'game', forBeforeFetch: ['gamePlatFormId', 'betWay', 'dateFrom', 'dateTo', 'partnerId', 'userIdList'] },
            },
        ],
        menuVisible: true,
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.DATE_RANGE,
                dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo' },
                label: '数据时间段',
            },
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'partnerId',
                label: '品牌',
                placeholder: '全部',
                selectOptions: [],
            },
            {
                type: TableSearchEnum.MULTI_SELECT,
                matchKey: 'userIdList',
                label: '所属代理',
                selectOptions: [],
            },
        ],
        isShowPagging: false,
    },
    {
        pageName: 'gameBrand',
        pageId: ReportPageEnum.GAME_BRAND,
        permissionId: PermissionsId.GameWinLossReport,
        api: {
            ...api.gameDetails,
            initParams: { ...baseRequest },
            insertParamsBeforeFetch: (request, refList) => {
                const gamePlatFormId = refList?.find((v) => v.key === 'gamePlatFormId')?.value;
                const betWay = refList?.find((v) => v.key === 'betWay')?.value;
                request['GamePlatFormId'] = gamePlatFormId;
                request['BetWay'] = betWay;
                request.DateFrom = refList?.find((v) => v.key === 'dateFrom')?.value || dayjs().startOf('day').format(DATETIME_FORMAT.YMDHMSS);
                request.DateTo = refList?.find((v) => v.key === 'dateTo')?.value || dayjs().endOf('day').format(DATETIME_FORMAT.YMDHMSS);
                const partnerId = refList?.find((v) => v.key === 'partnerId')?.value || '0';
                request.PartnerId = ~~partnerId;
                request.UserIdList = refList?.find((v) => v.key === 'userIdList')?.value || [];
                return request;
            },
        },
        direactableKeyList: [{ key: 'userName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        headerType: TableHeaderType.SEARCH_WITH_TEXT,
        headerText: [
            { title: 'Th_betUser', key: '', keyRef: 'totalRecords', type: TableColumnStyle.Numeric },
            { title: 'Lbl_date_range', key: 'dateStartToEnd', refObj: 'requestData', type: TableColumnStyle.Text },
        ],
        search: [
            {
                type: TableSearchEnum.INPUT,
                matchKey: 'userName',
                label: '会员帐号',
                placeholder: '请输入会员帐号',
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'memberFirstCharge',
        pageId: ReportPageEnum.FIRST_CHARGE,
        permissionId: PermissionsId.MemberFirstDepositReport,
        api: { ...api.firstRechargeReport, initParams: { ...todayRequest, ...{ UserIdList: undefined } } },
        direactableKeyList: [{ key: 'userName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        menuVisible: true,
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.DATE_RANGE,
                dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo' },
                label: '数据时间段',
            },
            {
                type: TableSearchEnum.INPUT,
                matchKey: 'userName',
                label: '会员帐号',
                placeholder: '请输入会员帐号',
                hasFuzzy: false,
            },
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'partnerId',
                label: '品牌',
                placeholder: '全部',
                selectOptions: [],
            },
            {
                type: TableSearchEnum.MULTI_SELECT,
                matchKey: 'userIdList',
                label: '所属代理',
                selectOptions: [],
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'rebate',
        pageId: ReportPageEnum.REBATE,
        permissionId: PermissionsId.BonusRebateReport,
        api: { ...api.rebateReport, initParams: { ...todayRequest, ...{ UserIdList: undefined } } },
        direactableKeyList: [
            { key: 'bonusUser', directLayout: ReportPageEnum.REBATE_BONUS_DETAIL, payload: { forBeforeFetch: ['date', 'partnerId', 'userIdList'] } },
            { key: 'rebateUser', directLayout: ReportPageEnum.REBATE_USER_DETAIL, payload: { forBeforeFetch: ['date', 'partnerId', 'userIdList'] } },
        ],
        menuVisible: true,
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.DATE_RANGE,
                dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo' },
                label: '数据时间段',
            },
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'partnerId',
                label: '品牌',
                placeholder: '全部',
                selectOptions: [],
            },
            {
                type: TableSearchEnum.MULTI_SELECT,
                matchKey: 'userIdList',
                label: '所属代理',
                selectOptions: [],
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'rebate-bonus',
        pageId: ReportPageEnum.REBATE_BONUS_DETAIL,
        permissionId: PermissionsId.BonusRebateReport,
        api: {
            ...api.rebateDetails,
            initParams: { ...baseRequest, IsOrderByRebate: false },
            insertParamsBeforeFetch: (request, refList) => {
                // request必須要加一個Date欄位
                const refDate = refList?.find((v) => v.key === 'date')?.value;
                request['Date'] = dayjs(refDate).format(DATETIME_FORMAT.YMDHMSS);
                request.DateFrom = dayjs(refDate).startOf('day').format(DATETIME_FORMAT.YMDHMSS);
                request.DateTo = dayjs(refDate).endOf('day').format(DATETIME_FORMAT.YMDHMSS);
                const partnerId = refList?.find((v) => v.key === 'partnerId')?.value || '0';
                request.PartnerId = ~~partnerId;
                request.UserIdList = refList?.find((v) => v.key === 'userIdList')?.value || [];
                return request;
            },
        },
        direactableKeyList: [{ key: 'userName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.INPUT,
                matchKey: 'userName',
                label: '会员帐号',
                placeholder: '请输入会员帐号',
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'rebate-user',
        pageId: ReportPageEnum.REBATE_USER_DETAIL,
        permissionId: PermissionsId.BonusRebateReport,
        api: {
            ...api.rebateDetails,
            initParams: { ...baseRequest, IsOrderByRebate: true },
            insertParamsBeforeFetch: (request, refList) => {
                // request必須要加一個Date欄位
                request['Date'] = refList?.find((v) => v.key === 'date')?.value;
                const partnerId = refList?.find((v) => v.key === 'partnerId')?.value || '0';
                request.PartnerId = ~~partnerId;
                request.UserIdList = refList?.find((v) => v.key === 'userIdList')?.value || [];
                return request;
            },
        },
        direactableKeyList: [{ key: 'userName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.INPUT,
                matchKey: 'userName',
                label: '会员帐号',
                placeholder: '请输入会员帐号',
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'charge-and-withdraw',
        pageId: ReportPageEnum.CHARGE_AND_REDRAW,
        permissionId: PermissionsId.DepositWithdrawalDetails,
        api: { ...api.chargeWithdrawalReport, initParams: { ...todayRequest, ...{ UserIdList: undefined } } },
        direactableKeyList: [
            { key: 'rechargeUser', directLayout: ReportPageEnum.RECHARGE_DEPOSIT_DETAIL, payload: { forBeforeFetch: ['date', 'partnerId', 'userIdList'] } },
            { key: 'withdrawUser', directLayout: ReportPageEnum.WITHDRAW_DEPOSIT_DETAIL, payload: { forBeforeFetch: ['date', 'partnerId', 'userIdList'] } },
        ],
        menuVisible: true,
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.DATE_RANGE,
                dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo' },
                label: '数据时间段',
            },
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'partnerId',
                label: '品牌',
                placeholder: '全部',
                selectOptions: [],
            },
            {
                type: TableSearchEnum.MULTI_SELECT,
                matchKey: 'userIdList',
                label: '所属代理',
                selectOptions: [],
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'charge-and-withdraw-recharge',
        pageId: ReportPageEnum.RECHARGE_DEPOSIT_DETAIL,
        permissionId: PermissionsId.DepositWithdrawalDetails,
        api: {
            ...api.depositDetails,
            initParams: { ...baseRequest, SearchStatus: SearchDepositTradeStatus.ALL, SearchTradeType: DepositTradeType.RECHARGWE },
            insertParamsBeforeFetch: (request, refList) => {
                const getDate = refList?.find((v) => v.key === 'date')?.value;
                const dateObj = getDate ? dayjs(getDate) : dayjs();
                request.DateFrom = dateObj.startOf('day').format(DATETIME_FORMAT.YMDHMSS);
                request.DateTo = dateObj.endOf('day').format(DATETIME_FORMAT.YMDHMSS);
                const partnerId = refList?.find((v) => v.key === 'partnerId')?.value || '0';
                request.PartnerId = ~~partnerId;
                request.UserIdList = refList?.find((v) => v.key === 'userIdList')?.value || [];
                return request;
            },
        },
        direactableKeyList: [{ key: 'userName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.INPUT,
                matchKey: 'userName',
                label: '会员帐号',
                placeholder: '请输入会员帐号',
            },
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'searchStatus',
                label: '状态',
                placeholder: '全部',
                selectOptions: [
                    { id: SearchDepositTradeStatus.ALL, text: 'Lbl_Partner_All' },
                    { id: SearchDepositTradeStatus.PROCESSING, text: 'Txt_processing' },
                    { id: SearchDepositTradeStatus.SUCCESS, text: 'Txt_success' },
                    { id: SearchDepositTradeStatus.FAILURE, text: 'Txt_fail' },
                ],
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'charge-and-withdraw-withdraw_user',
        pageId: ReportPageEnum.WITHDRAW_DEPOSIT_DETAIL,
        permissionId: PermissionsId.DepositWithdrawalDetails,
        api: {
            ...api.depositDetails,
            initParams: { ...baseRequest, SearchStatus: SearchDepositTradeStatus.ALL, SearchTradeType: DepositTradeType.WITHDRAW, IsOrderByRebate: true },
            insertParamsBeforeFetch: (request, refList) => {
                const getDate = refList?.find((v) => v.key === 'date')?.value;
                const dateObj = getDate ? dayjs(getDate) : dayjs();
                request.DateFrom = dateObj.startOf('day').format(DATETIME_FORMAT.YMDHMSS);
                request.DateTo = dateObj.endOf('day').format(DATETIME_FORMAT.YMDHMSS);
                const partnerId = refList?.find((v) => v.key === 'partnerId')?.value || '0';
                request.PartnerId = ~~partnerId;
                request.UserIdList = refList?.find((v) => v.key === 'userIdList')?.value || [];
                return request;
            },
        },
        direactableKeyList: [{ key: 'userName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.INPUT,
                matchKey: 'userName',
                label: '会员帐号',
                placeholder: '请输入会员帐号',
            },
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'searchStatus',
                label: '状态',
                placeholder: '全部',
                selectOptions: [
                    { id: SearchDepositTradeStatus.ALL, text: 'Lbl_Partner_All' },
                    { id: SearchDepositTradeStatus.PROCESSING, text: 'Txt_processing' },
                    { id: SearchDepositTradeStatus.SUCCESS, text: 'Txt_success' },
                    { id: SearchDepositTradeStatus.FAILURE, text: 'Txt_fail' },
                ],
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'agent-recharge',
        pageId: ReportPageEnum.AGENT_RECHARGE,
        permissionId: PermissionsId.RechargeDetails,
        api: { ...api.agentRechargeReport, initParams: { ...todayRequest, ...{ UserIdList: undefined } } },
        direactableKeyList: [{ key: 'modifyUserName', isPopupModel: true, showPopupPermission: PermissionsId.MemberStatistics }],
        menuVisible: true,
        headerType: TableHeaderType.SEARCH,
        search: [
            {
                type: TableSearchEnum.DATE_RANGE,
                dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo' },
                label: '数据时间段',
            },
            {
                type: TableSearchEnum.INPUT,
                matchKey: 'userName',
                label: '会员帐号',
                placeholder: '请输入会员帐号',
                hasFuzzy: true,
            },
            {
                type: TableSearchEnum.SELECT,
                matchKey: 'partnerId',
                label: '品牌',
                placeholder: '全部',
                selectOptions: [],
            },
            {
                type: TableSearchEnum.MULTI_SELECT,
                matchKey: 'userIdList',
                label: '所属代理',
                selectOptions: [],
            },
        ],
        isShowPagging: true,
    },
    {
        pageName: 'relation-member',
        pageId: ReportPageEnum.RELATION_MEMBER,
        permissionId: PermissionsId.RelatedMembersReport,
        api: { ...api.relationMemberReport, initParams: { ...thisMonthRequest, UserName: '', GroupName: '' } },
        direactableKeyList: [
            {
                key: 'relationAccountCount',
                isPopupModel: false,
                directLayout: ReportPageEnum.RELATION_MEMBER_DETAIL,
                payload: { forBeforeTrensfer: ['month', 'groupId', 'groupName'], forBeforeFetch: ['month', 'groupId', 'groupName'] },
            },
        ],
        menuVisible: true,
        headerType: TableHeaderType.SEARCH_COLUMN,
        search: [
            [
                {
                    type: TableSearchEnum.DATE_RANGE_MONTH,
                    dateMatchKey: { startDate: 'DateFrom', endDate: 'DateTo', shortcuts: false },
                    label: '数据时间段',
                },
                {
                    type: TableSearchEnum.INPUT,
                    matchKey: 'groupName',
                    label: '群组名称',
                    placeholder: '请输入群组名称',
                    hasFuzzy: true,
                    fuzzyKey: 'IsGroupNameFuzzySearch',
                },
                {
                    type: TableSearchEnum.INPUT,
                    matchKey: 'userName',
                    label: '会员帐号',
                    placeholder: '请输入会员帐号',
                    hasFuzzy: true,
                    fuzzyKey: 'IsUserNameFuzzySearch',
                },
                {
                    type: TableSearchEnum.SELECT,
                    matchKey: 'betRate',
                    label: '投注率',
                    selectOptions: [
                        { id: 0, text: 'Lbl_Partner_All' },
                        { id: 1, text: 'Lbl_BettingRateEnum_1' },
                        { id: 2, text: 'Lbl_BettingRateEnum_2' },
                        { id: 3, text: 'Lbl_BettingRateEnum_3' },
                        { id: 4, text: 'Lbl_BettingRateEnum_4' },
                        { id: 5, text: 'Lbl_BettingRateEnum_5' },
                    ],
                },
            ],
            [
                {
                    type: TableSearchEnum.RANGE_SELECT,
                    label: '充值金额',
                    placeholder: '不限',
                    matchKey: 'rechargeAmount',
                    rangeSelectOptions: {
                        start: numberListMapping.n0to70000.map((text, id) => ({ id, text: text.toString() })),
                        end: numberListMapping.n500toi.map((text, id) => ({ id, text: text.toString() })),
                    },
                },
                {
                    type: TableSearchEnum.RANGE_SELECT,
                    label: '代充金额',
                    placeholder: '不限',
                    matchKey: 'upAmount',
                    rangeSelectOptions: {
                        start: numberListMapping.n0to70000.map((text, id) => ({ id, text: text.toString() })),
                        end: numberListMapping.n500toi.map((text, id) => ({ id, text: text.toString() })),
                    },
                },
                {
                    type: TableSearchEnum.RANGE_SELECT,
                    label: '输赢',
                    placeholder: '不限',
                    matchKey: 'winLose',
                    rangeSelectOptions: {
                        start: numberListMapping.nnito5000.map((text, id) => ({ id, text: text.toString() })),
                        end: numberListMapping.nn5000toi.map((text, id) => ({ id, text: text.toString() })),
                    },
                },
                {
                    type: TableSearchEnum.RANGE_SELECT,
                    label: '投注金额',
                    placeholder: '不限',
                    matchKey: 'betAmount',
                    rangeSelectOptions: {
                        start: numberListMapping.n0to15000.map((text, id) => ({ id, text: text.toString() })),
                        end: numberListMapping.n500toi.map((text, id) => ({ id, text: text.toString() })),
                    },
                },
            ],
        ],
        isShowPagging: true,
        noteComp: BettingRateNote,
    },
    {
        pageName: 'relation-member-detail',
        pageId: ReportPageEnum.RELATION_MEMBER_DETAIL,
        permissionId: PermissionsId.RelatedMembersReport,
        api: {
            ...api.relationMemberReportDetail,
            initParams: { ...baseRequest },

            insertParamsBeforeFetch: (request, refList) => {
                const month = refList?.find((v) => v.key === 'month')?.value;
                (request as any)['ReportMonth'] = month;
                (request as any)['GroupId'] = refList?.find((v) => v.key === 'groupId')?.value;
                return request;
            },
        },
        menuVisible: false,
        headerType: TableHeaderType.TEXT,
        search: [],
        isShowPagging: true,
        headerText: [
            { title: 'Th_GroupName', key: 'groupName', refObj: 'requestData', type: TableColumnStyle.Text },
            { title: 'Th_month', key: 'month', refObj: 'requestData', type: TableColumnStyle.Text },
        ],
        noteComp: BettingRateNote,
    },
];
