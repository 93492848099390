import { useToast } from '@chakra-ui/react';
import { useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

function useCopyToClipboard(): [CopiedValue, CopyFn] {
    const [copiedText, setCopiedText] = useState<CopiedValue>(null);

    const copy: CopyFn = async (text) => {
        if (!navigator?.clipboard) {
            console.warn('Clipboard not supported');
            return false;
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            return true;
        } catch (error) {
            console.warn('Copy failed', error);
            setCopiedText(null);
            return false;
        }
    };

    return [copiedText, copy];
}

// To remain the simplicity of the hook, we can create a new hook that wraps the previous one and adds a toast notification
function useCopyToClipboardWithToast() {
    const toast = useToast();
    const [, copy] = useCopyToClipboard();

    const copyWithToast = async (text: string, title = '链结复制') => {
        const success = await copy(text);
        if (success) {
            toast({
                title: `${title}${success ? '成功' : '失败'}`,
                description: '',
                status: success ? 'success' : 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        return success;
    };

    return [copyWithToast];
}

export { useCopyToClipboardWithToast };

export default useCopyToClipboard;
