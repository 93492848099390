import { DialogButtonType } from '@/components/dialog/dialog';
import { useCopyToClipboardWithToast } from '@/hooks/useCopyToClipboard';
import { showDialog } from '@/store/slices/dialog.slice';
import { Button, ButtonGroup } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { ITransferStatusResponse } from '@/models/transferFetch/tramsferModel';

export type ITransferProgressCompParams = {
    onClose?: () => void;
    onCancel?: () => void;
    statusResponse?: ITransferStatusResponse;
};

export function TransferProgressComp(params: ITransferProgressCompParams) {
    const { statusResponse } = params;
    const { t } = useTranslation();
    const [copy] = useCopyToClipboardWithToast();
    const dispatch = useDispatch();

    const handelCancel = () => {
        dispatch(
            showDialog({
                title: t('Lbl_Trnasfer_Cancel_Tip'),
                content: t('Msg_Transfer_Cancel_content'),
                type: DialogButtonType.Warning,
                buttons: [
                    {
                        text: t('Lbl_Button_Cancle'),
                        type: 'cancel',
                        onClick: () => {
                            params.onClose && params.onClose();
                        },
                    },
                    {
                        text: t('Lbl_Transfer_Cancel'),
                        type: 'confirm',
                        onClick: () => {
                            params.onCancel && params.onCancel();
                        },
                    },
                ],
            }),
        );
    };

    return (
        <div className="flex-col flex ">
            <div className="flex-col mt-2 grow flex divide-y divide-dashed">
                {/* 充值訊息 */}
                <div className="flex justify-center p-2">
                    <div className=" text-center text-sm mt-2 text-gray-500 flex flex-col">
                        <span>{t('Msg_TransferInfo_InProgress')}</span>
                        <span>
                            {parse(t('Msg_TransferInfo_TimeLimit').replace('{0}', '30').replace('{1}', dayjs(statusResponse?.createDate).format('HH:mm:ss')))}
                        </span>
                    </div>
                </div>

                {/* 充值金額 */}
                <div className="flex flex-col p-2">
                    <div className="flex grow">
                        <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                            <span>{t('Lbl_TransferAmount')}</span>
                        </div>
                        <div className="basis-3/4 ml-1 flex-col flex mt-1">
                            <span className="mb-3 text-left ">
                                <span>{`${statusResponse?.digitalInfo?.amount} ≈ ${statusResponse?.digitalInfo?.digitalAmount} USDT`}</span>
                                <Button
                                    colorScheme="orange"
                                    variant="link"
                                    onClick={() => copy(`${statusResponse?.digitalInfo?.digitalAmount}`, t('Lbl_copy'))}>
                                    {t('Lbl_copy')}
                                </Button>
                            </span>
                            <div className=" text-left text-sm text-gray-500">
                                <span className="icon icon-info text-red-500 " />
                                <span className=" text-sm text-gray-500">{t('Msg_TransferAmountWarring_1')}</span>
                                <span className=" text-sm text-red-500">{t('Msg_TransferAmountWarring_2')}</span>
                                <span className=" text-xs text-gray-500">{`${t('Lbl_TransferRate')}: ${statusResponse?.digitalInfo?.rate}`}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 錢包協議 */}
                <div className="flex grow p-2">
                    <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                        <span>{t('Lbl_WalletProtocol')}</span>
                    </div>
                    <div className="basis-3/4 ml-1 flex-col flex mt-1">
                        <span className="mb-1 text-left ">
                            <span>{statusResponse?.digitalInfo?.protocol}</span>
                            <Button colorScheme="orange" variant="link" onClick={() => copy(`"${statusResponse?.digitalInfo?.protocol}"`, t('Lbl_copy'))}>
                                {t('Lbl_copy')}
                            </Button>
                        </span>
                        <span className=" text-xs text-red-500">{t('Msg_WalletProtocol')}</span>
                    </div>
                </div>

                {/* 充值地址 */}
                <div className="flex grow p-2">
                    <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                        <span>{t('Lbl_TransferAddress')}</span>
                    </div>
                    <div className="basis-3/4 ml-1 flex-col flex mt-1">
                        <div className="mb-1">
                            <div>
                                <QRCodeCanvas id="qr-code-address" value={statusResponse?.digitalInfo?.blockAddress || ''} fgColor="black" size={160} />
                            </div>
                        </div>
                        <span className="mb-1 text-left ">
                            <span className="font-bold text-gray-600">{statusResponse?.digitalInfo?.blockAddress}</span>
                            <Button colorScheme="orange" variant="link" onClick={() => copy(`"${statusResponse?.digitalInfo?.blockAddress}"`, t('Lbl_copy'))}>
                                {t('Lbl_copy')}
                            </Button>
                        </span>
                    </div>
                </div>

                {/* 充值單號 */}
                <div className="flex grow p-2">
                    <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                        <span>{t('Lbl_TransferTicketNo')}</span>
                    </div>
                    <div className="basis-3/4 ml-1 flex-col flex mt-1">
                        <span className="mb-1 text-left ">
                            <span>{statusResponse?.digitalInfo?.orderId}</span>
                            <Button colorScheme="orange" variant="link" onClick={() => copy(`"${statusResponse?.digitalInfo?.orderId}"`, t('Lbl_copy'))}>
                                {t('Lbl_copy')}
                            </Button>
                        </span>
                    </div>
                </div>

                {/* 充值方式 */}
                <div className="flex grow p-2">
                    <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                        <span>{t('Lbl_TransferType')}</span>
                    </div>
                    <div className="basis-3/4 ml-1 flex-col flex mt-1">
                        <span className="mb-1 text-left ">
                            <span>{statusResponse?.paymentName}</span>
                            <Button colorScheme="orange" variant="link" onClick={() => copy(`"${statusResponse?.paymentName}"`, t('Lbl_copy'))}>
                                {t('Lbl_copy')}
                            </Button>
                        </span>
                    </div>
                </div>

                {/* 操作 */}
                <div className="flex grow justify-center sticky bottom-0 inset-x-0 bg-white">
                    <div className="ml-1 flex-col p-2">
                        <ButtonGroup gap="2">
                            <Button colorScheme="gray" onClick={() => handelCancel()}>
                                {t('Btn_Transfer_Cancel')}
                            </Button>
                            <Button colorScheme="orange" onClick={() => params.onClose && params.onClose()}>
                                {t('Lbl_Button_Confirm')}
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>

                {/* 轉帳訊息 */}
                {/* <div className="flex grow m-2">
                  <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                      <span>{t('Msg_TransferInfo_InProgress')}</span>
                  </div>
                  <div className="basis-3/4 ml-1 flex-col flex"></div>
              </div> */}
            </div>
        </div>
    );
}
