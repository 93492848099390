import { useRadioGroup, UseRadioProps, useRadio, Box } from '@chakra-ui/react';
import { PropsWithChildren, useEffect } from 'react';

export type IRadioCardGroupParams = {
    name?: string;
    defaultValue?: string;
    options: { text: string; value: string }[];
    onChange?: (key: string) => void;
    value?: string;
};

export function RadioCardGroup(params: IRadioCardGroupParams) {
    const { name = 'RadioCardGroup', defaultValue = '', onChange, options, value } = params;

    const { getRadioProps, setValue } = useRadioGroup({
        name: name,
        defaultValue: defaultValue,
        onChange: onChange,
    });

    useEffect(() => {
        if (value === undefined) return;
        setValue(value || '');
    }, [value]);

    return (
        <div className=" flex flex-wrap gap-1 ">
            {options.map((item) => {
                const radio = getRadioProps({ value: item.value });
                return (
                    <RadioCard key={item.value} {...radio}>
                        {item.text}
                    </RadioCard>
                );
            })}
        </div>
    );
}

export function RadioCard(props: PropsWithChildren<UseRadioProps>) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as="label" width="24">
            <input {...input} />
            <Box
                className=" text-center"
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                _checked={{
                    bg: 'orange',
                    color: 'gray.100',
                    _hover: { bg: 'orange' },
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                _hover={{
                    bg: 'orange.500',
                }}
                px={5}
                py={3}>
                {props.children}
            </Box>
        </Box>
    );
}
