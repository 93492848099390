import LoadingMask from '@/components/loadingMask/loadingMask';
import { PopupModel } from '@/components/popupModal/popupModal';
import { UseDisclosureProps, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransferProgressComp } from './TransferProgressComp';
import { PARTNER_ENUM } from '@/assets/config/common/partner.config';
import { TransferUpdateComp } from './TransferUpdateComp';
import { TransferPartnerComp } from './TransferPartnerComp';
import {
    ITransferRequestModel,
    ITransferBaseRequest,
    ITransferStatusResponse,
    ITransferPaymentResponse,
    ITransferCancelRequest,
    ITransferCancelResponse,
} from '@/models/transferFetch/tramsferModel';
import useFetchApi from '@/hooks/useFetchApi';
import { api } from '@/service/api';
import { showDialog } from '@/store/slices/dialog.slice';
import { DialogButtonType } from '@/components/dialog/dialog';
import { useDispatch } from 'react-redux';

export type ITransferPopupProps = {
    readonly?: boolean;
} & UseDisclosureProps;

export enum TransferStepEnum {
    Partner = 1,
    Update = 2,
    InProgress = 3,
    Cancel = 4,
}

export const TransferPopup = (params: ITransferPopupProps) => {
    const { t } = useTranslation();
    const [step, setStep] = useState(TransferStepEnum.Partner);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch();

    const popUpParams = {
        ...params,
        title: t('Lbl_TransferRecharge'),
    };
    const [statusRequest, setStatusRequest] = useState<ITransferBaseRequest<object>>();
    const [statusResponse, setStatusResponse] = useState<ITransferStatusResponse>();
    const [paymentResponse, setPaymentResponse] = useState<ITransferPaymentResponse>();

    const detectFetchStatusHandler = useFetchApi<ITransferBaseRequest<object>, ITransferStatusResponse, ITransferBaseRequest<object>>(
        api.getTransferStatus,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [_payload] }) => {
            if (value.data.IsSuccess) {
                setStatusResponse(value.data.Data);
                setStep(TransferStepEnum.Update);
            } else {
                dispatch(
                    showDialog({
                        title: t('Lbl_Button_Warring'),
                        content: value.data.Message,
                        type: DialogButtonType.Error,
                    }),
                );
            }
            setIsLoading(false);
            return value.data.Data;
        },
        [],
        (error) => {
            setIsLoading(false);
            console.log('ERROR', error);
        },
    );

    const detectFetchPaymentHandler = useFetchApi<
        ITransferBaseRequest<ITransferRequestModel>,
        ITransferPaymentResponse,
        ITransferBaseRequest<ITransferRequestModel>
    >(
        api.getTransferStatus,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [_payload] }) => {
            console.log('value', value.data);

            if (value.data.IsSuccess) {
                setPaymentResponse(value.data.Data);
                setStep(TransferStepEnum.InProgress);
            } else {
                dispatch(
                    showDialog({
                        title: t('Lbl_Button_Warring'),
                        content: value.data.Message,
                        type: DialogButtonType.Error,
                    }),
                );
            }
            setIsLoading(false);
            return value.data.Data;
        },
        [],
        (error) => {
            setIsLoading(false);
            console.log('ERROR', error);
        },
    );

    const detectFetchCancelHandler = useFetchApi<
        ITransferBaseRequest<ITransferCancelRequest>,
        ITransferCancelResponse,
        ITransferBaseRequest<ITransferCancelRequest>
    >(
        api.getTransferStatus,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [_payload] }) => {
            console.log('value', value.data);

            if (value.data.IsSuccess) {
                toast({
                    title: t('Lbl_Trnasfer_Cancel_Success'),
                    position: 'top-right',
                    status: 'success',
                    isClosable: true,
                });
                params.onClose && params.onClose();
            } else {
                dispatch(
                    showDialog({
                        title: t('Lbl_Button_Warring'),
                        content: value.data.Message,
                        type: DialogButtonType.Error,
                    }),
                );
            }
            setIsLoading(false);
            return value.data.Data;
        },
        [],
        (error) => {
            setIsLoading(false);
            console.log('ERROR', error);
        },
    );

    const handelPartnerConfirm = (statusRequest: ITransferBaseRequest<object>) => {
        setStatusRequest(statusRequest);
        detectFetchStatusHandler(statusRequest);
    };

    const handelUpdateConfirm = (transferRequest: ITransferRequestModel) => {
        detectFetchPaymentHandler({
            action: 'transfer',
            agentUserId: statusRequest?.agentUserId || 0,
            partnerId: statusRequest?.partnerId || 0,
            reqData: transferRequest,
        });
    };

    const handelCancelTransfer = () => {
        detectFetchCancelHandler({
            action: 'cancel',
            agentUserId: statusRequest?.agentUserId || 0,
            partnerId: statusRequest?.partnerId || 0,
            reqData: { orderId: statusResponse?.orderId || '' },
        });
    };

    useEffect(() => {
        if (!statusResponse) return;
        setStep(!!statusResponse.digitalInfo ? TransferStepEnum.InProgress : TransferStepEnum.Update);
    }, [statusResponse]);

    useEffect(() => {
        if (!paymentResponse) return;
        if (!paymentResponse.tradeNo || paymentResponse.tradeNo.length === 0) return;
        if (statusRequest) {
            detectFetchStatusHandler(statusRequest);
        }
    }, [paymentResponse]);

    return (
        <div>
            <LoadingMask visible={isLoading} />
            <PopupModel {...popUpParams} isCentered={false}>
                <div className="">
                    {step === TransferStepEnum.Partner && <TransferPartnerComp onClose={params.onClose} onConfirm={handelPartnerConfirm} />}
                    {step === TransferStepEnum.Update && (
                        <TransferUpdateComp
                            onClose={params.onClose}
                            statusResponse={statusResponse}
                            onConfirm={handelUpdateConfirm}
                            partnerId={statusRequest?.partnerId}
                            agentUserId={statusRequest?.agentUserId}
                        />
                    )}
                    {step === TransferStepEnum.InProgress && (
                        <TransferProgressComp statusResponse={statusResponse} onClose={params.onClose} onCancel={handelCancelTransfer} />
                    )}
                </div>
            </PopupModel>
        </div>
    );
};

export type ITransferCancelRequestModel = {
    orderId: string;
};

export type ITransferCancelResponseModel = {
    showTip: boolean;
};
