import { getEnableConfig, IPartnerConfig, PARTNER_ENUM } from '@/assets/config/common/partner.config';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import { ITransferBaseRequest, STATUS_ACTION } from '@/models/transferFetch/tramsferModel';
import getAppStorageUtil, { STORAGE_KEYS } from '@/service/appStorageUtil';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { t } from 'i18next';
import { useMemo, useState } from 'react';

export type ITransferPartnerCompParams = {
    onClose?: () => void;
    onConfirm?: (response: ITransferBaseRequest<object>) => void;
};

export function TransferPartnerComp(params: ITransferPartnerCompParams) {
    const UNSELECTED_NUM = -1;
    const [, getItem] = getAppStorageUtil();
    const [isLoading, setIsLoading] = useState(false);
    const [statusRequest, setStatusRequest] = useState<ITransferBaseRequest<object>>({
        action: STATUS_ACTION.status,
        partnerId: PARTNER_ENUM.None,
        agentUserId: UNSELECTED_NUM,
        reqData: {},
    });

    const partners = useMemo(() => {
        return getEnableConfig()
            .filter((config) => config.id !== PARTNER_ENUM.N8)
            .map((config) => {
                return {
                    id: config.id,
                    text: t(`Lbl_Partner_${config.text}`),
                } as IPartnerConfig;
            });
    }, [t]);

    const partnerMapping = partners.reduce(
        (mapping, obj) => {
            mapping[obj.id] = obj;
            return mapping;
        },
        {} as { [key: number]: IPartnerConfig },
    );

    const agents = useMemo(() => {
        const items = (getItem(STORAGE_KEYS.AGENTPARTNER_KEY) || []) as IAgentPartner[];
        return items.filter((item) => item.PartnerId === statusRequest.partnerId);
    }, [statusRequest.partnerId]);

    const agentsMapping = useMemo(() => {
        const items = (getItem(STORAGE_KEYS.AGENTPARTNER_KEY) || []) as IAgentPartner[];

        return items
            .filter((item) => item.PartnerId === statusRequest?.partnerId)
            .reduce(
                (mapping, obj) => {
                    mapping[obj.AgentUserId] = obj;
                    return mapping;
                },
                {} as { [key: number]: IAgentPartner },
            );
    }, [statusRequest.partnerId]);

    const handleConfirm = async () => {
        if (!statusRequest) return;
        if (statusRequest.agentUserId === UNSELECTED_NUM) return;
        if (params.onConfirm) params.onConfirm(statusRequest);
    };

    return (
        <>
            {/* 品牌 */}
            <div className="flex grow m-2">
                <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                    <span>{t('Th_partnerId')}:</span>
                </div>
                <div className="basis-3/4 ml-1 flex-col flex">
                    <Menu matchWidth={true}>
                        <MenuButton as={Button}>
                            {statusRequest.partnerId !== PARTNER_ENUM.None ? partnerMapping[statusRequest.partnerId].text : t('Msg_PleaseSelectPartner')}
                            <ChevronDownIcon />
                        </MenuButton>
                        <MenuList height={100}>
                            {partners.map((item) => (
                                <MenuItem
                                    color={statusRequest.partnerId === item.id ? 'gray.100' : ''}
                                    bg={statusRequest.partnerId === item.id ? 'orange' : ''}
                                    _hover={{ bg: 'orange', color: 'gray.100' }}
                                    key={`MI_${item.id}`}
                                    value={item.id}
                                    onClick={() => setStatusRequest((v) => ({ ...v, partnerId: item.id, agentUserId: UNSELECTED_NUM }))}>
                                    {item.text}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </div>
            </div>

            {/* 代理 */}
            <div className="flex grow m-2">
                <div className="basis-1/4 text-right text-sm mt-2 text-gray-500">
                    <span>{t('Th_agentNickName')}:</span>
                </div>
                <div className="basis-3/4 ml-1 flex-col flex">
                    <Menu matchWidth={true}>
                        <MenuButton as={Button}>
                            {statusRequest.agentUserId !== UNSELECTED_NUM ? agentsMapping[statusRequest.agentUserId].NickName : t('Msg_PleaseSelectAgent')}
                            <ChevronDownIcon />
                        </MenuButton>
                        <MenuList>
                            {agents.map((item) => (
                                <MenuItem
                                    color={statusRequest?.agentUserId === item.AgentUserId ? 'gray.100' : ''}
                                    bg={statusRequest?.agentUserId === item.AgentUserId ? 'orange' : ''}
                                    _hover={{ bg: 'orange', color: 'gray.100' }}
                                    key={`AG_${item.AgentUserId}`}
                                    value={item.AgentUserId}
                                    onClick={() => setStatusRequest((v) => ({ ...v, agentUserId: item.AgentUserId }))}>
                                    {item.NickName}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </div>
            </div>

            {/* 確認 */}
            <div className="flex grow justify-center sticky bottom-0 inset-x-0 bg-white">
                <div className="ml-1 flex-col">
                    <ButtonGroup gap="2">
                        <Button colorScheme="orange" onClick={() => handleConfirm()}>
                            {t('Lbl_Button_Confirm')}
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </>
    );
}
