import { PARTNER_ENUM } from '@/assets/config/common/partner.config';

export const TransferProtocol = {
    TRC20: 'TRC20',
    ERC20: 'ERC20',
} as const;

export type ITransferProtocolTypeValue = keyof typeof TransferProtocol;

export type ITransferInfoModel = {
    remainingBalance: number;
    creditBalance: number;
};

export type ITransferRequestModel = {
    paymentId: number; // 1002
    merchantAppId: number; // 2529
    limitType: number; // 1
    appType: number; // 2
    protocol: ITransferProtocolTypeValue;
    amount: number;
};

export type ITransferCancelRequest = {
    orderId: string;
};

export type ITransferCancelResponse = {
    showTip: boolean;
};

export const STATUS_ACTION = {
    status: 'status',
    transfer: 'transfer',
    cancel: 'cancel',
} as const;
export type ISTATUS_ACTION_Type = keyof typeof STATUS_ACTION;

export type ITransferBaseRequest<T> = {
    action: ISTATUS_ACTION_Type;
    partnerId: PARTNER_ENUM;
    agentUserId: number;
    reqData: T;
};

export type ITransferWalletInfo = {
    balance: number;
    validAmount: number;
    validCreditAmount: number;
};

export type ITransferMerchant = {
    appId: number;
    channelId: number;
    channelName: string;
    maxAmount: number;
    minAmount: number;
    merchantCode: string;
    merchantName: string;
    protocolMap: { [key: string]: string };
    recommendAmount: number;
};

export type ITransferPaymentInfo = {
    protocolsSort: { msg: string; name: string; sort: number }[];
    limitType: number;
    paymentId: number;
    paymentName: string;
    merchantList: ITransferMerchant[];
};

export type ITransferDigitalInfo = {
    amount: number;
    blockAddress: string;
    digitalAmount: number;
    keyword: string;
    orderId: string;
    owner: string;
    protocol: string;
    rate: number;
    realRate: number;
};

export type ITransferStatusResponse = {
    createDate?: number;
    paymentName?: string;
    amountlist?: number[];
    digitalInfo?: ITransferDigitalInfo;
    paymentInfo?: ITransferPaymentInfo[];
    walletInfo?: ITransferWalletInfo;
    orderId?: string;
};

export type ITransferPartnerAgentUser = {
    partnerId: PARTNER_ENUM;
    agentUserId: number;
};

export type ITransferPaymentResponse = {
    digitalInfo?: ITransferDigitalInfo;
    amount: number;
    paymentName: string;
    tradeNo: string;
    errorCode: number;
    errorMsg: string;
};
