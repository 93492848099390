import { getEnableConfig, PARTNER_ENUM, IPartnerConfig } from '@/assets/config/common/partner.config';
import { IAgentPartner } from '@/models/page/logon/ILogon';
import getAppStorageUtil, { STORAGE_KEYS } from '@/service/appStorageUtil';
import { Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type IPartnerTableDisplayCompParam = {
    partnerId: PARTNER_ENUM;
    agentUserId: number;
};

export function PartnerTableDisplayComp(params: IPartnerTableDisplayCompParam) {
    const { partnerId, agentUserId } = params;
    const [, getItem] = getAppStorageUtil();
    const { t } = useTranslation();

    const partners = useMemo(() => {
        return getEnableConfig()
            .filter((config) => config.id !== PARTNER_ENUM.N8)
            .map((config) => {
                return {
                    id: config.id,
                    text: t(`Lbl_Partner_${config.text}`),
                } as IPartnerConfig;
            });
    }, [t]);

    const partnerMapping = partners.reduce(
        (mapping, obj) => {
            mapping[obj.id] = obj;
            return mapping;
        },
        {} as { [key: number]: IPartnerConfig },
    );

    const agentsMapping = useMemo(() => {
        const items = (getItem(STORAGE_KEYS.AGENTPARTNER_KEY) || []) as IAgentPartner[];

        return items
            .filter((item) => item.PartnerId === partnerId)
            .reduce(
                (mapping, obj) => {
                    mapping[obj.AgentUserId] = obj;
                    return mapping;
                },
                {} as { [key: number]: IAgentPartner },
            );
    }, [partnerId]);

    return (
        <>
            {/* 品牌 */}
            <div className="flex grow p-2">
                <div className="basis-1/4 text-right text-gray-500">
                    <span>{t('Th_partnerId')}:</span>
                </div>

                <div className="basis-3/4 ml-1 flex-col flex">
                    <Text>{partnerMapping[partnerId]?.text}</Text>
                </div>
            </div>

            {/* 代理 */}
            <div className="flex grow p-2">
                <div className="basis-1/4 text-right text-gray-500">
                    <span>{t('Th_agentNickName')}:</span>
                </div>

                <div className="basis-3/4 ml-1 flex-col flex">
                    <Text>{agentsMapping[agentUserId].NickName}</Text>
                </div>
            </div>
        </>
    );
}
